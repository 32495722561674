import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import SwiperBanner from "../components/SwiperBanner";
//import SwiperBanner from "../components/SwiperBannerLao"; //Fuxuser44
//import Partner from "../components/PatherTh"; // สำหรับไทย
//import Partner from "../components/PatherLottoTh"; // สำหรับไทยหวย
//import Partner from "../components/PatherTh95"; // สำหรับ Sbo95
//import Partner from "../components/PatherThThai"; // สำหรับ Sbo95
import Partner from "../components/Partner"; // สำหรับลาว
//import Partner from "../components/PartnerLotto"; // สำหรับ AMB
import Service from "../components/Service";
//import Service from "../components/ServiceTh95"; // สำหรับ Sbo95
import ModalPost from "../components/ModalPost";
import Announcement from "../components/Announcement";
import { gamelist, bannerpopup } from '../store/user'
import Popup from '../components/Popup'

function Home() {
    const dispatch = useDispatch()
    const {showpop} = useSelector(state => state.user);
    useEffect(()=> {
        dispatch(gamelist({}))
        dispatch(bannerpopup({type:2}))
    } ,[])

    const loadbanner =()=> {
        dispatch(bannerpopup({type:3}))
    }

    return (
        <div>
            <Announcement/>
            <SwiperBanner/>
            <div className={'gold-line'}/>
            <Partner/>
            <div className={'gold-line'}/>
            <Service/>
            <ModalPost show={false} onHide={()=>{}} />
            {
                /*
 <Popup  show={showpop} onHide={()=>loadbanner()} />
                */
            }
           
        </div>
    );
}

export default Home;
